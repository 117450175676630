.footer {
    background-color: #fff;
}

.footer__body {
    margin-left: auto;
    margin-right: auto;
    max-width: 976px;
    text-align: center;
}

.footer__body__links > * {
    display: inline-block;
}

.footer__body__links > * + * {
    margin-left: 20px;
    position: relative;
    padding-top: 0 !important;
}

.footer__body__links > * + *:before {
    content: '';
    height: 14px;
    left: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    background-color: rgb(35, 47, 62);
    position: absolute;
}

@media (min-width: 659px) {
    .footer__body {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }

    .footer__body__links {
        text-align: left;
    }
}

@media (min-width: 1201px) {
    .footer__body {
        max-width: 1248px;
    }
}

.awsui {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

.app-content {
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
}

.loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--awsui-color-background-modal-overlay);
    z-index: 1000;
}

.ClassMeta_overflowHiddenText__37yG9 {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* 
This is temp. We plan to migrate away from Paloma Card to custom Polaris cards
https://sim.amazon.com/issues/BKR-2476
*/
.badge {
  float: right; }

.not-active-wrapper .completed {
  border-top: 1px solid #d5dbdb; }

.not-active-wrapper > div {
  border: 1px solid #aab7b8; }
  .not-active-wrapper > div:hover {
    background-color: transparent;
    background-color: initial;
    box-shadow: none;
    box-shadow: initial;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial; }

.awsui .hero-banner__title {
  color: white; }

