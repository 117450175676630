.loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--awsui-color-background-modal-overlay);
    z-index: 1000;
}
