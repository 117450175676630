@import '../colors.scss';
/* 
This is temp. We plan to migrate away from Paloma Card to custom Polaris cards
https://sim.amazon.com/issues/BKR-2476
*/

.badge {
    float: right;
}

.not-active-wrapper {
    .completed {
        border-top: 1px solid $zinc-color;
    }

    > div {
        border: 1px solid $silver-color;

        &:hover {
            background-color: initial;
            box-shadow: initial;
            transform: initial;
        }
    }
}
